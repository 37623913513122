<template>
  <div>
    <v-card
      :style="[
        $vuetify.breakpoint.smAndDown
          ? { 'min-height': '100vh', 'background-color': '#f9f9f9' }
          : { 'background-color': '#f9f9f9' },
      ]"
    >
      <div style="margin-left: 10px; margin-right: 10px">
        <div
          class="pt-8"
          style="
            font-size: 20px;
            margin-left: 1rem;
            margin-right: 1rem;
            color: #444444;
          "
        >
          Transaction Redemption
        </div>
        <v-card-text>
          <div>
            <v-card
              elevation="10"
              style="padding: 2%; margin-bottom: 2%; margin-top: 2%"
            >
              <v-row>
                <v-col cols="12" md="2" class="pt-5">
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startDate"
                        label="Start Date"
                        append-icon="mdi-calendar-month"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        outlined
                        hide-details="auto"
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      @change="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="2" class="pt-5">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="endDate"
                        label="End Date"
                        append-icon="mdi-calendar-month"
                        outlined
                        hide-details="auto"
                        dense
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      @change="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <!-- <v-col cols="12" sm="2" md="2" lg="2" class="pt-5">
                    <v-select
                      :items="['Car', 'Motorcycle']"
                      label="Type"
                      v-model="vehicleType"
                      required
                      clearable
                      outlined
                      dense
                    ></v-select>
                  </v-col> -->
                <v-col cols="12" sm="5" md="5" lg="5" class="pt-5">
                  <v-text-field
                    label="Search"
                    append-icon="mdi-database-search-outline"
                    v-model="search"
                    v-on:keyup.enter="SearchTransactionVisitor(search)"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="3" style="text-align: end">
                  <v-hover v-slot="{ hover }">
                    <v-btn
                      :color="'blue'"
                      class="ma-2 white--text"
                      :elevation="hover ? 10 : 2"
                      :class="{ 'on-hover': hover }"
                      @click="getDataVisitorRedemption()"
                    >
                      Search

                      <v-icon right> mdi-magnify </v-icon>
                    </v-btn>
                  </v-hover>
                  <v-hover v-slot="{ hover }">
                    <!-- <v-btn
                      color="orange"
                      :elevation="hover ? 10 : 2"
                      class="ma-2 white--text"
                      @click="exportDataTransactionRedemption()"
                    >
                      Export CSV
                      <v-icon right>mdi-file-chart-outline</v-icon>
                    </v-btn> -->
                    <vue-excel-xlsx
                      :data="dataExport"
                      :columns="columns"
                      :file-name="'Transaction Redemption ' + Date.now()"
                      :file-type="'xlsx'"
                      :sheet-name="'Transaction Redemption'"
                    >
                      <v-btn color="orange" class="ma-2 white--text">
                        Export Excel
                        <v-icon right>mdi-file-excel-outline</v-icon>
                      </v-btn>
                    </vue-excel-xlsx>
                  </v-hover>
                </v-col>
              </v-row>
            </v-card>

            <v-card
              elevation="10"
              style="padding: 2%; margin-top: 2%; margin-bottom: 2%"
            >
              <v-row>
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="3"></v-col>
                <v-col cols="12" md="5" align="center">
                  <v-pagination
                    v-model="page"
                    :total-visible="6"
                    :length="pageCount"
                    @input="ChangePage(page)"
                  ></v-pagination>
                </v-col>
              </v-row>
              <v-data-table
                :headers="headers"
                :items="RedemptionList"
                hide-default-footer
                style="color: #332f2fde"
                :items-per-page="itemsPerPage"
                :page.sync="page"
                single-line
                hide-details
              >
                <template v-slot:item="{ item, index }">
                  <tr>
                    <td class="header-table-css">{{ no_run + (index + 1) }}</td>
                    <td class="header-table-css">{{ item.logCarparkID }}</td>
                    <!-- <td class="header-table-css">{{ ChangeFormatDate(item.dateTimeIn) }}</td> -->
                    <td class="header-table-css">{{ item.licensePlate }}</td>
                    <td class="header-table-css">{{ item.vehicleType }}</td>
                    <td class="header-table-css">{{ item.rateCode }}</td>
                    <td class="header-table-css">{{ item.tenant }}</td>
                    <td class="header-table-css">{{ item.createBy }}</td>
                    <td class="header-table-css">
                      {{ ChangeFormatDate(item.createTime) }}
                    </td>
                    <td style="text-align: center">
                      <v-btn
                        v-if="permissionsPage.read"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="orange"
                        @click="OpenTransactionRedemptionDialog(item)"
                      >
                        <v-icon dark> mdi-eye </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <br />
              <v-row class="mt-5">
                <v-col cols="12" md="4"></v-col>
                <v-col cols="12" md="3">
                  <v-pagination
                    v-model="page"
                    :total-visible="20"
                    :length="pageCount"
                    @input="ChangePage(page)"
                  ></v-pagination>
                </v-col>
                <v-col cols="12" md="3"></v-col>
                <v-col cols="12" md="2">
                  <v-autocomplete
                    dense
                    solo
                    label="10/page"
                    v-model="itemsPerPage"
                    @input="ChangePerPage(itemsPerPage)"
                    :items="items"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-card-text>
      </div>
    </v-card>

    <v-dialog
      v-model="TransactionRedemptionDialog"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-card-title style="background-color: black; color: white">
          <span class="text-h5">Redemption Detail</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row style="margin-top: 1%">
              <v-col cols="12" md="12" sm="12">
                <v-row>
                  <v-col cols="12" md="3" sm="3">
                    <p style="font-size: 18px">LogCarpark :</p>
                    <p style="font-size: 18px">DateTime In :</p>
                    <p style="font-size: 18px">LicensePlate :</p>
                    <p style="font-size: 18px">VehicleType :</p>
                    <br />
                    <p style="font-size: 18px">RateTenant :</p>
                    <p style="font-size: 18px">RateCode :</p>
                    <p style="font-size: 18px">RateDetail :</p>
                  </v-col>
                  <v-col cols="12" md="9" sm="9">
                    <p style="font-size: 18px; color: black">
                      {{ ViewData.logCarparkID }}
                    </p>
                    <p style="font-size: 18px; color: black">
                      {{ ChangeFormatDate(ViewData.dateTimeIn) }}
                    </p>
                    <p style="font-size: 18px; color: black">
                      {{ ViewData.licensePlate }}
                    </p>
                    <p style="font-size: 18px; color: black">
                      {{ ViewData.vehicleType }}
                    </p>
                    <br />
                    <p style="font-size: 18px; color: black">
                      {{ ViewData.tenant }}
                    </p>
                    <p style="font-size: 18px; color: black">
                      {{ ViewData.rateCode }}
                    </p>
                    <p style="font-size: 18px; color: black">
                      {{ ViewData.rateDetail }}
                    </p>
                    <p></p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3" sm="3">
                    <p style="font-size: 18px">DateTime Stamp :</p>
                    <p style="font-size: 18px">Stamp By :</p>
                    <p style="font-size: 18px">Remark :</p>
                  </v-col>
                  <v-col cols="12" md="9" sm="9">
                    <p style="font-size: 18px; color: black">
                      {{ ChangeFormatDate(ViewData.createTime) }}
                    </p>
                    <p style="font-size: 18px; color: black">
                      {{ ViewData.createBy }}
                    </p>
                    <p style="font-size: 18px; color: black">
                      {{ ViewData.remark }}
                    </p>
                  </v-col>
                </v-row>
              </v-col>
              <v-row style="text-align: -webkit-center"> </v-row>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
              style="text-align: end; margin-bottom: 20px"
            >
              <v-spacer></v-spacer>
              <v-btn
                variant="outlined"
                color="silver"
                style="min-width: 100px"
                append-icon="mdi-close-circle-outline"
                @click="TransactionRedemptionDialog = false"
              >
                Close
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="LoadingDialog" persistent width="auto">
      <div class="loading-box">
        <div class="inter-load">
          <div class="rect rect1"></div>
          <div class="rect rect2"></div>
          <div class="rect rect3"></div>
          <div class="rect rect4"></div>
          <div class="rect rect5"></div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import enurl from "@/api/environment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { eventBus } from "../../main";

export default {
  name: "TransactionRedemption",
  components: {},
  data() {
    return {
      loader: null,
      url: enurl.apiUrl,
      LoadingDialog: false,
      items: [10, 15, 20, 50, 100],
      no_run: null,
      pageCount: 0,
      page: 1,
      itemsPerPage: 10,
      AlertDialog: false,
      TypeAlert: null,
      MessageAlert: "",

      TransactionRedemptionDialog: false,
      RedemptionList: [],
      ViewData: [],
      search: "",
      startDate: "",
      endDate: "",
      menuStartDate: false,
      menuEndDate: false,

      menu2: false,
      menu: false,
      menu1: false,
      modal: false,

      permissionsPage: [],
      // vehicleType: null,
      dataExport: [],

      columns: [
        {
          label: "",
          field: "logCarpark",
        },
        {
          label: "",
          field: "datetimeIn",
        },
        {
          label: "",
          field: "licensePlate",
        },
        {
          label: "",
          field: "vehicleType",
        },
        {
          label: "",
          field: "rateCode",
        },
        {
          label: "",
          field: "rateTenant",
        },
        {
          label: "",
          field: "createBy",
        },
        {
          label: "",
          field: "createTime",
        },
      ],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "No",
          sortable: false,
          align: "left",
        },
        {
          text: "LogCarpark",
          align: "left",
          sortable: false,
        },
        //   {
        //     text: "DatetimeIn",
        //     align: "left",
        //     sortable: false,
        //   },
        {
          text: "LicensePlate",
          align: "left",
          sortable: false,
        },
        {
          text: "VehicleType",
          align: "left",
          sortable: false,
        },
        {
          text: "RateCode",
          align: "left",
          sortable: false,
        },
        {
          text: "RateTenant",
          align: "left",
          sortable: false,
        },
        {
          text: "CreateBy",
          align: "left",
          sortable: false,
        },
        {
          text: "CreateTime",
          align: "left",
          sortable: false,
        },
        {
          text: "View",
          align: "center",
          sortable: false,
        },
      ];
    },
  },

  mounted() {
    let self = this;
    let Permision = localStorage.getItem("Permision");
    if (Permision == null || Permision == "") {
      this.$router.push("/Main");
    }
    let page = localStorage.getItem("page");
    this.permissionsPage = JSON.parse(Permision).find((x) => x.appName == page);

    let date = new Date();
    let currentDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    self.startDate = currentDate;
    self.getDataVisitorRedemption();
    self.exportDataTransactionRedemption();
  },

  methods: {
    getDataVisitorRedemption() {
      let self = this;
      self.LoadingDialog = true;
      self.page = 1;
      let temp = {
        page: 1,
        perpage: self.itemsPerPage,
        search: self.search == null ? "" : self.search,
        startDate: self.startDate,
        endDate: self.endDate,
      };
      axios
        .post(`${self.url}Redemption/getDataTransactionRedemption`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.RedemptionList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.count / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ChangePage(value) {
      let self = this;
      self.LoadingDialog = true;
      self.page = value;
      let temp = {
        page: value,
        perpage: self.itemsPerPage,
        startDate: self.startDate,
        endDate: self.endDate,
        search: self.search == null ? "" : self.search,
      };
      axios
        .post(`${self.url}Redemption/getDataTransactionRedemption`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.RedemptionList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.count / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ChangePerPage(value) {
      let self = this;
      self.LoadingDialog = true;
      self.page = 1;
      let temp = {
        page: 1,
        perpage: value,
        search: self.search == null ? "" : self.search,
        startDate: self.startDate,
        endDate: self.endDate,
      };
      axios
        .post(`${self.url}Redemption/getDataTransactionRedemption`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.RedemptionList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.count / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    SearchTransactionVisitor(data) {
      let self = this;
      self.LoadingDialog = true;
      self.page = 1;
      let temp = {
        page: 1,
        perpage: self.itemsPerPage,
        search: data,
        startDate: self.startDate,
        endDate: self.endDate,
      };
      axios
        .post(`${self.url}Redemption/getDataTransactionRedemption`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.RedemptionList = response.data.data.listData;
            self.pageCount = Math.ceil(
              response.data.data.count / self.itemsPerPage
            );
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    exportDataTransactionRedemption() {
      let self = this;
      self.LoadingDialog = true;
      let temp = {
        search: self.search,
        startDate: self.startDate,
        endDate: self.endDate,
      };
      axios
        .post(`${self.url}Redemption/exportDataTransactionRedemption`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.dataExport = response.data.data;
            // var tempdata = response.data.data;
            // const blob = self.base64ToBlob(tempdata, "text/csv;charset=utf-8;");
            // const linkSource = URL.createObjectURL(blob);
            // var link = document.createElement("a");
            // var date = new Date();
            // link.download =
            //   "TransactionRedemptionReport_" +
            //   date.toLocaleString("th-TH") +
            //   ".csv";
            // link.href = linkSource;
            // link.click();
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    base64ToBlob(base64, type = "application/octet-stream") {
      const binStr = atob(base64);
      const len = binStr.length;
      const arr = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      return new Blob([arr], { type: type });
    },

    ChangeFormatDate(date) {
      if (!date) return null;

      var DateData = new Date(date);
      var hours = DateData.getHours();
      var minutes = DateData.getMinutes();
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes;
      return (
        DateData.getDate() +
        "/" +
        (DateData.getMonth() + 1) +
        "/" +
        DateData.getFullYear() +
        "  " +
        strTime
      );
    },

    OpenTransactionRedemptionDialog(data) {
      let self = this;
      let temp = {
        id: data.id,
      };
      axios
        .post(`${self.url}Redemption/getDataTransactionRedemptionByID`, temp)
        .then(function (response) {
          if (response.data.status == 0) {
            self.TransactionRedemptionDialog = true;
            self.BindingData(response.data.data);
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
          self.LoadingDialog = false;
        })
        .catch(function (error) {
          self.LoadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    BindingData(data) {
      let self = this;
      self.ViewData = data;
    },

    ChangeFormatDateNoTime(date) {
      if (!date) return null;
      var DateData = new Date(date);
      return (
        DateData.getDate() +
        "/" +
        (DateData.getMonth() + 1) +
        "/" +
        DateData.getFullYear()
      );
    },
  },
};
</script>
<style scoped>
* >>> .v-data-table-header {
  background-color: black !important;
  color: #ffffff !important;
}
* >>> .v-data-table-header th {
  font-size: 14px !important;
  color: #ffffff !important;
}
.addUserTitle {
  font-size: 14px;
  font-weight: 300;
}
.input-border {
  border: 1px solid black;
  height: 35px;
  padding: 4px;
}
.validation-class {
  color: red;
  font-size: 12px;
}
.header-table-css {
  text-align: left;
  cursor: pointer;
}

*,
::before ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #555555;
}

.loading-box {
  width: 150px;
  height: 100px;
  border: 5px solid #f1f1f1;
  /* margin: 100px auto; */
  position: relative;
}

.inter-load {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.rect {
  background: #f1f1f1;
  display: inline-block;
  height: 60px;
  width: 7px;
  margin: 0 1px;
  animation: load 1.3s infinite ease-in-out;
}

@keyframes load {
  0% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
  40% {
    transform: scaleY(0.4);
  }
  100% {
    transform: scaleY(0.4);
  }
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.rect2 {
  animation-delay: -1.2s;
}
.rect3 {
  animation-delay: -1.1s;
}
.rect4 {
  animation-delay: -1s;
}
.rect5 {
  animation-delay: -0.9s;
}
</style>
