<template>
  <div>
    <!-- Main section -->
    <div
      :style="[
        $vuetify.breakpoint.smAndDown
          ? { 'min-height': '100vh', 'background-color': '#f9f9f9' }
          : { 'background-color': '#f9f9f9' },
      ]"
    >
      <div style="text-align: left">
        <div class="pt-4 ma-3" style="font-size: 20px; color: #444444">
          <v-icon large left> mdi-printer </v-icon>
          <span>Print E-Tax Invoice</span>

          <v-card-text>
            <div>
              <v-card
                elevation="10"
                style="padding: 2%; margin-bottom: 2%; margin-top: 2%"
              >
                <v-row>
                  <v-col cols="12" sm="12" md="12" lg="12" class="pt-5">
                    <v-row>
                      <v-col cols="12" sm="12" md="12" lg="12">
                        <v-row>
                          <v-col cols="12" sm="6" md="2" lg="2" class="pt-5">
                            <v-menu
                              v-model="menuStartDate"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="startDate"
                                  label="Start Date"
                                  append-icon="mdi-calendar"
                                  outlined
                                  dense
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  clearable
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="startDate"
                                @change="menuStartDate = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" sm="6" md="2" lg="2" class="pt-5">
                            <v-menu
                              v-model="menuEndDate"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="endDate"
                                  label="End Date"
                                  append-icon="mdi-calendar"
                                  outlined
                                  dense
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  clearable
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="endDate"
                                @change="menuEndDate = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-col>

                          <v-col cols="12" sm="2" md="2" lg="2" class="pt-5">
                            <v-text-field
                              label="Trn Tax No"
                              append-icon="mdi-database-search-outline"
                              v-model="Trn_Tax_No"
                              clearable
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="2" md="2" lg="2" class="pt-5">
                            <v-text-field
                              label="Name"
                              append-icon="mdi-database-search-outline"
                              v-model="name"
                              clearable
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            md="4"
                            lg="4"
                            class="mt-1"
                            style="text-align: end"
                          >
                            <v-btn
                              :color="'green'"
                              class="ma-2 white--text"
                              @click="CreateEtax()"
                            >
                              Create E-Tax Invoice

                              <v-icon right> mdi-magnify </v-icon>
                            </v-btn>
                            <v-btn
                              :color="'blue'"
                              class="ma-2 white--text"
                              @click="getTransactionETaxInvoice()"
                            >
                              Search

                              <v-icon right> mdi-magnify </v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>

              <v-card v-if="showData" class="v-card-panel" elevation="1">
                <v-card elevation="10" class="v-card-panel" width="500">
                  <v-card-title>ข้อมูลผู้ขอใบกำกับภาษี </v-card-title>
                  <v-card-subtitle
                    >Tax invoice requester information</v-card-subtitle
                  >
                  <v-card-text>
                    <v-form ref="form">
                      <v-text-field
                        v-model="form.tax_invoice_no"
                        label="เลขกำกับภาษี / Tax invoice no. *"
                        required
                        clearable
                        filled
                        dense
                        clear-icon="mdi-close-circle"
                        :rules="[(v) => !!v || 'กรุณากรอกเลขกำกับภาษี']"
                      ></v-text-field>

                      <v-text-field
                        v-model="form.name"
                        label="ชื่อ-นามสกุล / Full name *"
                        required
                        clearable
                        filled
                        dense
                        clear-icon="mdi-close-circle"
                        :rules="[(v) => !!v || 'กรุณากรอกชื่อ-นามสกุล']"
                      ></v-text-field>

                      <v-text-field
                        v-model="form.tax_ID"
                        label="เลขประจำตัวผู้เสียภาษี / Tax ID *"
                        required
                        clearable
                        filled
                        dense
                        clear-icon="mdi-close-circle"
                        :rules="[
                          (v) => !!v || 'กรุณากรอกเลขประจำตัวผู้เสียภาษี',
                        ]"
                        :counter="13"
                      ></v-text-field>

                      <v-textarea
                        v-model="form.address"
                        label="ที่อยู่ / Address *"
                        required
                        clearable
                        filled
                        dense
                        clear-icon="mdi-close-circle"
                        :rules="[(v) => !!v || 'กรุณากรอกที่อยู่']"
                        rows="2"
                      ></v-textarea>

                      <v-btn class="mr-4" color="yellow" @click="BackToMenu()">
                        <v-icon left> mdi-arrow-left </v-icon>
                        Back
                      </v-btn>
                      <v-btn class="mr-4" @click="previewETaxInvoice()">
                        <v-icon left> mdi-eye </v-icon>
                        Preview
                      </v-btn>
                      <v-btn
                        class="mr-4"
                        color="primary"
                        @click="generateETaxInvoice()"
                      >
                        <v-icon left dark> mdi-printer </v-icon>
                        Print
                      </v-btn>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-card>
              <v-card
                v-else
                elevation="10"
                style="
                  padding-left: 2%;
                  margin-top: 2%;
                  margin-bottom: 2%;
                  padding-right: 2%;
                "
              >
                <v-row>
                  <v-col cols="12" md="6"></v-col>
                  <v-col cols="12" md="6" align="center">
                    <v-pagination
                      v-model="page"
                      :total-visible="8"
                      :length="pageCount"
                      @input="ChangePage(page)"
                    ></v-pagination>
                  </v-col>
                </v-row>
                <v-data-table
                  :headers="headers"
                  :items="DataList"
                  hide-default-footer
                  style="color: #332f2fde"
                  :items-per-page="itemsPerPage"
                  :page.sync="page"
                  single-line
                  hide-details
                >
                  <template v-slot:item="{ item, index }">
                    <tr>
                      <td class="header-table-center-css">
                        {{ item.rowNum }}
                      </td>
                      <td class="header-table-center-css">
                        {{ item.eTaxInvoiceID }}
                      </td>
                      <td class="header-table-center-css">
                        {{ item.trn_Tax_No }}
                      </td>
                      <td
                        style="min-width: 150px"
                        class="header-table-center-css"
                      >
                        {{ ChangeFormatDate(item.printDate) }}
                      </td>
                      <td class="header-table-css">
                        {{ item.taxID }}
                      </td>
                      <td class="header-table-css">
                        {{ item.name }}
                      </td>

                      <td class="header-table-center-css">
                        {{ item.trn_Total + " THB" }}
                      </td>

                      <td style="text-align: center; min-width: 150px">
                        <v-btn
                          class="mx-2"
                          fab
                          dark
                          small
                          color="blue"
                          @click="generateETaxInvoiceView(item)"
                        >
                          <v-icon dark> mdi-file-pdf-box </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <br />
                <v-row class="mt-5">
                  <v-col cols="12" md="4"></v-col>
                  <v-col cols="12" md="3">
                    <v-pagination
                      v-model="page"
                      :total-visible="20"
                      :length="pageCount"
                      @input="ChangePage(page)"
                    ></v-pagination>
                  </v-col>
                  <v-col cols="12" md="3"></v-col>
                  <v-col cols="12" md="2">
                    <v-autocomplete
                      dense
                      solo
                      label="10/page"
                      v-model="itemsPerPage"
                      @input="ChangePerPage(itemsPerPage)"
                      :items="items"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </v-card-text>
        </div>
      </div>
    </div>

    <!-- Preview PDF dialog -->
    <v-dialog v-model="dialogPreviewPDF" max-width="800px" persistent>
      <v-card>
        <v-card-title class="headline">E-Tax Invoice Preview</v-card-title>
        <v-card-text>
          <div style="position: relative">
            <iframe
              :src="pdfPreviewUrl"
              type="application/pdf"
              class="pdf-Preview"
            ></iframe>
            <div @click.prevent @contextmenu.prevent class="pdf-overlay"></div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialogPreviewPDF = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Loading dialog -->
    <v-dialog v-model="loadingDialog" persistent width="auto">
      <div class="loading-box">
        <div class="inter-load">
          <div class="rect rect1"></div>
          <div class="rect rect2"></div>
          <div class="rect rect3"></div>
          <div class="rect rect4"></div>
          <div class="rect rect5"></div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import enurl from "@/api/environment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { eventBus } from "../../main";
import { generatePDF } from "@/plugins/generateETaxInvoice.js";

export default {
  name: "ETaxInvoice",

  data() {
    const defaultForm = Object.freeze({
      save_log_mode: false,
      tax_invoice_no: "",
      name: "",
      tax_ID: "",
      address: "",
    });

    return {
      url: enurl.apiUrl,
      urlCarpark: enurl.apiCarparkUrl,
      loadingDialog: false,
      items: [10, 15, 20, 50, 100],
      no_run: null,
      pageCount: 0,
      page: 1,
      itemsPerPage: 10,
      AlertDialog: false,
      TypeAlert: null,
      MessageAlert: "",

      DataList: [],
      DataListCarparkID: [],
      DataListImgID: [],
      ConfigTerminal: [],
      DropdownConfigTerminal: [],
      DropdownTerminalIn: [],
      terminalIDIn: null,
      terminalIDOut: null,
      DropdownTerminalOut: [],
      ImageList: [],

      search: "",
      activeStatus: null,
      menuStartDate: false,
      menuEndDate: false,
      TransactionCarparkDialog: false,
      EditStatus: false,
      ShowImageDialog: false,
      ShowLogDialog: false,
      ShowReprintImageDialog: false,
      DialogReprint: false,
      ShowImageText: "",

      // Customer input form
      form: Object.assign({}, defaultForm),
      defaultForm,

      // PDF file
      dialogPreviewPDF: false,
      pdfPreviewUrl: null,
      showData: false,

      startDate: "",
      endDate: "",

      name: "",
      Trn_Tax_No: "",
      Terminal_ID: "",

      columns: [
        {
          label: "",
          field: "LogEntry",
        },
        {
          label: "",
          field: "LicensePlate",
        },
        {
          label: "",
          field: "Tenant",
        },
        {
          label: "",
          field: "DatetimeIn",
        },
        {
          label: "",
          field: "DatetimeOut",
        },
        {
          label: "",
          field: "VehicleType",
        },
        {
          label: "",
          field: "TicketNo",
        },
        {
          label: "",
          field: "TerminalIn",
        },
        {
          label: "",
          field: "TerminalOut",
        },
      ],
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "No",
          sortable: false,
          align: "center",
        },
        {
          text: "ETax Invoice ID",
          align: "center",
          sortable: false,
        },
        {
          text: "Trn Tax No",
          align: "center",
          sortable: false,
        },
        {
          text: "Print Date",
          align: "center",
          sortable: false,
        },
        {
          text: "Tax ID",
          align: "center",
          sortable: false,
        },
        {
          text: "Name",
          align: "center",
          sortable: false,
        },

        {
          text: "Trn Total",
          align: "center",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
        },
      ];
    },
  },

  async mounted() {
    let self = this;
    let permission = localStorage.getItem("Permision");
    if (permission == null || permission == "") {
      this.$router.push("/Main");
    }
    let page = localStorage.getItem("page");
    this.permissionsPage = JSON.parse(permission).find(
      (x) => x.appName == page
    );

    await self.getTransactionETaxInvoice();
  },

  methods: {
    async CreateEtax() {
      let self = this;
      // self.loadingDialog = true;
      self.clearMOdel();
      if (self.showData == true) {
        self.showData = false;
      } else {
        self.showData = true;
      }
    },
    async BackToMenu() {
      let self = this;
      self.showData = false;
    },

    async clearMOdel(){
      let self = this;
      self.form = Object.assign({}, self.defaultForm);

    },

    async getTransactionETaxInvoice() {
      let self = this;
      self.loadingDialog = true;
      self.page = 1;
      let temp = {
        page: 1,
        pageSize: self.itemsPerPage,
        startDate: self.startDate == "" ? null : self.startDate,
        endDate: self.endDate == "" ? null : self.endDate,
        name: self.name == "" ? null : self.name,
        Trn_Tax_No: self.Trn_Tax_No == "" ? null : self.Trn_Tax_No,
      };
      await axios
        .post(
          `${self.urlCarpark}PaymentGateway/getTransactionETaxInvoice`,
          temp
        )
        .then(function (response) {
          if (response.status == 200) {
            self.DataList = response.data.items;
            self.pageCount = response.data.totalPages.count;
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          self.loadingDialog = false;
        })
        .catch(function (error) {
          self.loadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ChangePage(value) {
      let self = this;
      self.loadingDialog = true;
      self.page = value;
      let temp = {
        page: self.page,
        pageSize: self.itemsPerPage,
        startDate: self.startDate == "" ? null : self.startDate,
        endDate: self.endDate == "" ? null : self.endDate,
        name: self.name == "" ? null : self.name,
        Trn_Tax_No: self.Trn_Tax_No == "" ? null : self.Trn_Tax_No,
      };
      axios
        .post(
          `${self.urlCarpark}PaymentGateway/getTransactionETaxInvoice`,
          temp
        )
        .then(function (response) {
          if (response.status == 200) {
            self.DataList = response.data.items;
            self.pageCount = response.data.totalPages.count;
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          self.loadingDialog = false;
        })
        .catch(function (error) {
          self.loadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },

    ChangePerPage(value) {
      let self = this;
      self.loadingDialog = true;
      let temp = {
        page: self.page,
        pageSize: value,
        startDate: self.startDate == "" ? null : self.startDate,
        endDate: self.endDate == "" ? null : self.endDate,
        name: self.name == "" ? null : self.name,
        Trn_Tax_No: self.Trn_Tax_No == "" ? null : self.Trn_Tax_No,
      };
      axios
        .post(
          `${self.urlCarpark}PaymentGateway/getTransactionETaxInvoice`,
          temp
        )
        .then(function (response) {
          if (response.status == 200) {
            self.DataList = response.data.items;
            self.pageCount = response.data.totalPages.count;
            self.no_run = (self.page - 1) * self.itemsPerPage;
          }
          self.loadingDialog = false;
        })
        .catch(function (error) {
          self.loadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });
    },
    ChangeFormatDate(date) {
      if (!date) return null;
      var DateData = new Date(date);
      var hours = DateData.getHours();
      var minutes = DateData.getMinutes();
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes;
      return (
        DateData.getDate() +
        "/" +
        (DateData.getMonth() + 1) +
        "/" +
        DateData.getFullYear() +
        "  " +
        strTime
      );
    },
    ChangeFormatDateOnly(date) {
      if (!date) return null;
      var DateData = new Date(date);
      // // var hours = DateData.getHours();
      // var minutes = DateData.getMinutes();
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      return (
        DateData.getDate() +
        "/" +
        (DateData.getMonth() + 1) +
        "/" +
        DateData.getFullYear()
      );
    },
    ChangeFormatTime(date) {
      if (!date) return null;
      var DateData = new Date(date);
      var hours = DateData.getHours();
      var minutes = DateData.getMinutes();
      var sec = DateData.getSeconds();
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + ":" + sec;
      return strTime;
    },

    async previewETaxInvoice() {
      let that = this;
      that.loadingDialog = true;
      if (that.$refs.form.validate()) {
        that.pdfPreviewUrl = "";
        that.$refs.form.resetValidation();

        that.form.save_log_mode = false;
        const [status, data_pdf] = await that.getDataForETaxInvoice(that.form);
        if (status) {
          const pdfDoc = await generatePDF(data_pdf, that.form.save_log_mode);
          const pdfBytes = await pdfDoc.save();
          that.pdfPreviewUrl =
            URL.createObjectURL(
              new Blob([pdfBytes], { type: "application/pdf" })
            ) + "#toolbar=0";

          that.loadingDialog = false;
          that.dialogPreviewPDF = true;
        }
      } else {
        that.loadingDialog = false;
      }
    },

    async generateETaxInvoice() {
      let that = this;
      that.loadingDialog = true;
      if (that.$refs.form.validate()) {
        that.$refs.form.resetValidation();

        that.form.save_log_mode = true;
        const [status, data_pdf] = await that.getDataForETaxInvoice(that.form);
        if (status) {
          const pdfDoc = await generatePDF(data_pdf, that.form.save_log_mode);
          const pdfBytes = await pdfDoc.save();
          const pdfUrl = URL.createObjectURL(
            new Blob([pdfBytes], { type: "application/pdf" })
          );

          window.open(pdfUrl, "_blank");
        }
      }
      that.loadingDialog = false;
    },

    async generateETaxInvoiceView(data) {
      let that = this;
      that.loadingDialog = true;

      that.form.tax_invoice_no = data.trn_Tax_No;
      that.form.name = data.name;
      that.form.tax_ID = data.taxID;
      that.form.address = data.address;

      that.form.save_log_mode = false;
      const [status, data_pdf] = await that.getDataForETaxInvoice(that.form);
      if (status) {
        const pdfDoc = await generatePDF(data_pdf, true);
        const pdfBytes = await pdfDoc.save();
        const pdfUrl = URL.createObjectURL(
          new Blob([pdfBytes], { type: "application/pdf" })
        );

        window.open(pdfUrl, "_blank");
      }

      that.loadingDialog = false;
    },

    async getDataForETaxInvoice(data) {
      let that = this;
      that.loadingDialog = true;
      let data_pdf = null;
      let status = false;

      await axios
        .post(`${that.urlCarpark}Redemption/GetDataETaxInvoice`, data)
        .then(function (response) {
          if (response.data.status == 0) {
            data_pdf = response.data.data;
            status = true;
          }
          if (response.data.status == 2) {
            eventBus.$emit("logout");
          }
        })
        .catch(function (error) {
          that.loadingDialog = false;
          if (error.response.status != 404) {
            Swal.fire({
              icon: "error",
              title: "Error...",
              width: 900,
              text: error.response.data.message,
            });
          }
        });

      return [status, data_pdf];
    },
  },
};
</script>

<style>
.v-card-panel {
  margin-left: auto;
  margin-right: auto;
  margin-top: 2%;
  margin-bottom: 2%;
  padding-top: 1%;
  padding-bottom: 1%;
}
.header-table-css {
  text-align: left;
  cursor: pointer;
}
.header-table-center-css {
  text-align: center;
  cursor: pointer;
}

.loading-box {
  width: 150px;
  height: 100px;
  border: 5px solid #f1f1f1;
  position: relative;
}

.inter-load {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.rect {
  background: #f1f1f1;
  display: inline-block;
  height: 60px;
  width: 7px;
  margin: 0 1px;
  animation: load 1.3s infinite ease-in-out;
}

@keyframes load {
  0% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
  40% {
    transform: scaleY(0.4);
  }
  100% {
    transform: scaleY(0.4);
  }
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.rect2 {
  animation-delay: -1.2s;
}
.rect3 {
  animation-delay: -1.1s;
}
.rect4 {
  animation-delay: -1s;
}
.rect5 {
  animation-delay: -0.9s;
}

.pdf-Preview {
  width: 100%;
  height: 500px;
  border: none;
  cursor: not-allowed;
}

.pdf-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  pointer-events: none;
  cursor: not-allowed;
}
</style>
