import { render, staticRenderFns } from "./RedemptionManagement.vue?vue&type=template&id=7f9454af&scoped=true"
import script from "./RedemptionManagement.vue?vue&type=script&lang=js"
export * from "./RedemptionManagement.vue?vue&type=script&lang=js"
import style0 from "./RedemptionManagement.vue?vue&type=style&index=0&id=7f9454af&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f9454af",
  null
  
)

export default component.exports