import Vue from "vue";
import Router from "vue-router";
import Login from "./views/Login";
import Main from "./views/Main";
import ManageMember from "./views/ManageMember";
import CentralMember from "./views/CentralMember";
import ManageUser from "./views/ManageUser";
import Tenant from "./views/Tenant";
import Dashboard from "./views/Dashboard";
import DashboardCarpark from "./views/DashboardCarpark";
import ManageRole from "./views/ManageRole";
import Camera from "./views/Camera/Camera";
import ManageTower from "./views/ManageTower";
import Beacons from "./views/Beacons";
import ManageZone from "./views/ManageZone";
import ManageCard from "./views/ManageCard";
import LocationMapping from "./views/LocationMapping";
import ManageMemberCarPark from "./views/CarparkManagement/ManageMemberCarPark";
import SearchCarInformation from "./views/CarparkManagement/SearchCarInformation";
import ManageCardCarpark from "./views/CarparkManagement/ManageCardCarpark";
import RedemptionManagement from "./views/CarparkManagement/RedemptionManagement";
import ManageSpending from "./views/CarparkManagement/ManageSpending";
import ManageRateProfile from "./views/CarparkManagement/ManageRateProfile";
import ManageRateTemplate from "./views/CarparkManagement/ManageRateTemplate";
import OpenBar from "./views/CarparkManagement/OpenBar";
import ManageRate from "./views/CarparkManagement/ManageRate";
import Weighing from "./views/CarparkManagement/Weighing";
import TransactionRedemption from "./views/CarparkManagement/TransactionRedemption";
import QRPreRegisterTransaction from "./views/VisitorManagement/QRPre-RegisterTransaction";
import EntranceTransaction from "./views/VisitorManagement/EntranceTransaction";
import TransactionVisitor from "./views/VisitorManagement/TransactionVisitor";
import TransactionTurnstile from "./views/TransactionTurnstile";
import Report from "./views/Report";
import SelectMenu from "./views/FirstPage/SelectMenu";
import DashboardVisitor from "./views/DashboardVisitor";
import DashboardResidence from "./views/Residence/Dashboard";
import MemberResidence from "./views/Residence/ManageMember";
import ManageCardResidence from "./views/Residence/ManageCardResidence";
import Residence from "./views/Residence/Residence";
import ManageRoom from "./views/Residence/ManageRoom";
import Accept from "./views/Accept";
import ManageInterfaceSAP from "./views/CarparkManagement/ManageInterfaceSAP";
import AdjustTransactionPayment from "./views/CarparkManagement/AdjustTransactionPayment";
import ReprintPayment from "./views/Payment/ReprintPayment";
import ReportPayment from "./views/Payment/ReportPayment";
import ExitCarparkPayment from "./views/Payment/ExitCarparkPayment";
import TransactionError from "./views/CarparkManagement/TransactionError";
import ETaxInvoice from "./views/Payment/ETaxInvoice";
import MifareCardManagement from "./views/CarparkManagement/MifareCardManagement";
import TransactionRedemptionResidence from "./views/CarparkManagement/TransactionRedemptionResidence";
import MifareCardAccess from "./views/MifareCardAccess";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: {
        name: "Login",
      },
    },
    {
      path: "/Login",
      name: "Login",
      component: Login,
    },
    {
      path: "/Accept",
      name: "Accept",
      component: Accept,
    },
    {
      path: "/Main",
      name: "Main",
      component: Main,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/Dashboard",
      name: "Dashboard",
      component: Dashboard,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/DashboardCarpark",
      name: "DashboardCarpark",
      component: DashboardCarpark,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/ManageMember",
      name: "ManageMember",
      component: ManageMember,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/CentralMember",
      name: "CentralMember",
      component: CentralMember,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/ManageUser",
      name: "ManageUser",
      component: ManageUser,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/Tenant",
      name: "Tenant",
      component: Tenant,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/Camera",
      name: "Camera",
      component: Camera,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/ManageRole",
      name: "ManageRole",
      component: ManageRole,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/ManageTower",
      name: "ManageTower",
      component: ManageTower,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/Beacons",
      name: "Beacons",
      component: Beacons,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/ManageZone",
      name: "ManageZone",
      component: ManageZone,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/ManageCard",
      name: "ManageCard",
      component: ManageCard,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/ManageMemberCarPark",
      name: "ManageMemberCarPark",
      component: ManageMemberCarPark,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/SearchCarInformation",
      name: "SearchCarInformation",
      component: SearchCarInformation,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/ReprintPayment",
      name: "ReprintPayment",
      component: ReprintPayment,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/ReportPayment",
      name: "ReportPayment",
      component: ReportPayment,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/ExitCarparkPayment",
      name: "ExitCarparkPayment",
      component: ExitCarparkPayment,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/ETaxInvoice",
      name: "ETaxInvoice",
      component: ETaxInvoice,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/ManageCardCarpark",
      name: "ManageCardCarpark",
      component: ManageCardCarpark,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/OpenBar",
      name: "OpenBar",
      component: OpenBar,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/RedemptionManagement",
      name: "RedemptionManagement",
      component: RedemptionManagement,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/ManageSpending",
      name: "ManageSpending",
      component: ManageSpending,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/ManageRateProfile",
      name: "ManageRateProfile",
      component: ManageRateProfile,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/ManageRateTemplate",
      name: "ManageRateTemplate",
      component: ManageRateTemplate,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/QRPreRegisterTransaction",
      name: "QRPreRegisterTransaction",
      component: QRPreRegisterTransaction,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/EntranceTransaction",
      name: "EntranceTransaction",
      component: EntranceTransaction,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/ManageRate",
      name: "ManageRate",
      component: ManageRate,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/SelectMenu",
      name: "SelectMenu",
      component: SelectMenu,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/TransactionVisitor",
      name: "TransactionVisitor",
      component: TransactionVisitor,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/TransactionTurnstile",
      name: "TransactionTurnstile",
      component: TransactionTurnstile,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/Report",
      name: "Report",
      component: Report,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/TransactionRedemption",
      name: "TransactionRedemption",
      component: TransactionRedemption,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/DashboardVisitor",
      name: "DashboardVisitor",
      component: DashboardVisitor,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/DashboardResidence",
      name: "DashboardResidence",
      component: DashboardResidence,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/MemberResidence",
      name: "MemberResidence",
      component: MemberResidence,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/ManageCardResidence",
      name: "ManageCardResidence",
      component: ManageCardResidence,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/Residence",
      name: "Residence",
      component: Residence,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/ManageRoom",
      name: "ManageRoom",
      component: ManageRoom,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/LocationMapping",
      name: "LocationMapping",
      component: LocationMapping,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/ManageInterfaceSAP",
      name: "ManageInterfaceSAP",
      component: ManageInterfaceSAP,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/AdjustTransactionPayment",
      name: "AdjustTransactionPayment",
      component: AdjustTransactionPayment,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/TransactionError",
      name: "TransactionError",
      component: TransactionError,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/MifareCardManagement",
      name: "MifareCardManagement",
      component: MifareCardManagement,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/MifareCardAccess",
      name: "MifareCardAccess",
      component: MifareCardAccess,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/Weighing",
      name: "Weighing",
      component: Weighing,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/TransactionRedemptionResidence",
      name: "TransactionRedemptionResidence",
      component: TransactionRedemptionResidence,
      meta: {
        requiresAuth: true,
      },
    },
   
  ],
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    var LoginStatus =
      localStorage.getItem("isLoggedIn") == "true" ? true : false;
    if (LoginStatus == true) {
      next();
      return;
    }
    next("/Login");
  } else {
    next();
  }
});
export default router;
